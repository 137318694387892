.bienvenida{
    transition: 1s;

}

.bienvenida_contenedor{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    height: 100vh;
    width: 100%;
    margin: 0px auto;
    position: relative;
    z-index: 1;
}


.fadeIn{
    opacity: 0;
}

.logoResiste{
    width: 35%;
}

.boton_tienda_resiste{
    width: 25%;
    cursor: pointer;
    margin-top: 25px;
}

.boton_tienda_resiste img{
    width: 100%;
}

.mayor_edad div:hover{
    background:#fda302;
}

/* MODAL */
.modal_contenedor{
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.37);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    transition: 1s;
}

.modal{
    padding: 60px;
    width: 500px;
    height: 250px;
    background: #fda302;
    display: flex;
    align-items: center;
}

.modal p{
    font-family: 'TitlingGothicFBWide-Bold';
    color: #2c267b;
    font-size: 25px;
}

.show_modal{
    opacity: 1;
    visibility: visible;
}

.terminos{
    width: 30%;
    text-align: center;
    font-size: 10px;
    margin-top: 20px;
}

.terminos .url_terminos{
    color: #fda302;
    cursor: pointer;
    text-decoration: none;
}

@media only screen and (min-width: 1000px) and (min-height: 500px) and (max-height: 580px){
    .logofep {
        background-repeat: no-repeat;
        width: 335px;
        height: 100px;
    }
}

@media only screen and (max-width: 430px){

    .logoResiste{
        width: 80%;
    }
    
    .boton_tienda_resiste{
        width: 50%;
        cursor: pointer;
    }
    
    .boton_tienda_resiste img{
        width: 100%;
    }


    
    
}