
.App{
  background: url('./assets/img/generales/fondo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  
  width: 100%;
  height: 100vh;
  position: relative;

  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  color: white;
}

.content_off{
  transition: 1s;
}

.terminos_contenedor{
  background: url('../src/assets/img/generales/fondo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  margin: 0px auto;
  text-align: center;
  margin-top: 65px;
  height: calc(100vh - 200px);
  overflow: scroll;
  position: absolute;
  right: 0;
  left: 0;
  overflow-x: hidden;
  padding: 20px;
}

.terminos_contenedor p{
  text-align: justify;
}

.terminos_contenedor p span{
  display: block;
  text-align: right;
  padding: 10px 0;
  cursor: pointer;
}

.terminos_contenedor p span .back{
  color: #fda302;
  text-decoration: none;
}

/* FONTS */

@font-face {
	font-family: 'TitlingGothicFBNor-Bold';
	src: url('./assets/fonts/TitlingGothicFBNor-Bold.eot');
	src: local('☺'), url('./assets/fonts/TitlingGothicFBNor-Bold.eot') format('woff'), url('./assets/fonts/TitlingGothicFBNor-Bold.eot') format('truetype'), url('./assets/fonts/TitlingGothicFBNor-Bold.eot') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TitlingGothicFBNor-Stand';
	src: url('./assets/fonts/TitlingGothicFBNor-Stand.eot');
	src: local('☺'), url('./assets/fonts/TitlingGothicFBNor-Stand.woff') format('woff'), url('./assets/fonts/TitlingGothicFBNor-Stand.ttf') format('truetype'), url('./assets/fonts/TitlingGothicFBNor-Stand.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TitlingGothicFBWide-Bold';
	src: url('./assets/fonts/TitlingGothicFBWide-Bold.eot');
	src: local('☺'), url('./assets/fonts/TitlingGothicFBWide-Bold.woff') format('woff'), url('./assets/fonts/TitlingGothicFBWide-Bold.ttf') format('truetype'), url('./assets/fonts/TitlingGothicFBWide-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TitlingGothicFBWide-Med';
	src: url('./assets/fonts/TitlingGothicFBWide-Med.eot');
	src: local('☺'), url('./assets/fonts/TitlingGothicFBWide-Med.woff') format('woff'), url('./assets/fonts/TitlingGothicFBWide-Med.ttf') format('truetype'), url('./assets/fonts/TitlingGothicFBWide-Med.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TitlingGothicFBWide-Reg';
	src: url('./assets/fonts/TitlingGothicFBWide-Reg.eot');
	src: local('☺'), url('./assets/fonts/TitlingGothicFBWide-Reg.woff') format('woff'), url('./assets/fonts/TitlingGothicFBWide-Reg.ttf') format('truetype'), url('./assets/fonts/TitlingGothicFBWide-Reg.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

.fadeCo{
  opacity: 0;
  visibility: hidden;
  animation: fadeInCo 2s forwards 2s;

}

@keyframes fadeInCo{
  from {opacity: 0;
    visibility: hidden;}
  to {opacity: 1;
    visibility: visible;}

}

/* CARITAS ANIMACIONES */
.carita{
  position: absolute;
}
.carita_amarilla{
  height: 367px;
  width: calc(10000px / 25);          
  background: url('./assets/img/generales/amarillo.png'); 
  animation: carita_amarilla 11s steps(25) infinite;
  top: -10%;
  right: 79%;
  transform: rotate(-30deg);
}


@keyframes carita_amarilla{
  0% {background-position: 0;
    transform:  translate(-41px,0px)  rotate(-20deg) scale(0.9)  perspective(550px) translateZ(0vw);
  }
  3% {
    transform:  translate(-51px,0px)  rotate(-15deg) scale(0.9)  perspective(550px) translateZ(0vw);
  }
  6% {
    transform:  translate(-58px,-10px)  rotate(-15deg) scale(0.9)  perspective(550px) translateZ(0vw);
  }
  9% {
    transform:  translate(-60px,-19px)  rotate(-15deg) scale(0.9)  perspective(550px) translateZ(0vw);
  }
  12% {
    transform:  translate(-61px,-27px)  rotate(-15deg) scale(0.9)  perspective(550px) translateZ(0vw);
  }
  16% {
    transform:  translate(-56px,-33px)  rotate(-15deg) scale(0.9)  perspective(550px) translateZ(0vw);
  }
  19% {
    transform:  translate(-51px,-37px)  rotate(-15deg) scale(0.89)  perspective(550px) translateZ(0vw);
  }
  23% {
    transform:  translate(-44px,-37px)  rotate(-15deg) scale(0.88) perspective(450px) translateZ(0.1vw);
  }
  27% {
    transform:  translate(-37px,-33px)  rotate(-15deg) scale(0.88) perspective(450px) translateZ(0.1vw);
  }
  31% {
    transform:  translate(-32px,-23px)  rotate(-15deg) scale(0.88) perspective(450px) translateZ(0.1vw);
  }
  35% {
    transform:  translate(-29px,-15px)  rotate(-15deg) scale(0.9) perspective(450px) translateZ(0.1vw);
  }
  41% {
    transform:  translate(-38px,-4px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0.1vw);
  }
  44% {
    transform:  translate(-51px,3px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0.1vw);
  }
  49% {
    transform:  translate(-67px,-6px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0.1vw);
  }
  53% {
    transform:  translate(-68px,-21px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0.1vw);
  }
  58% {
    transform:  translate(-62px,-36px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0.1vw);
  }
  61% {
    transform:  translate(-50px,-44px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0.2vw);
  }
  64% {
    transform:  translate(-42px,-38px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0.2vw);
  }
  68% {
    transform:  translate(-35px,-26px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0.2vw);
  }
  73% {
    transform:  translate(-32px,-10px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0.2vw);
  }
  77% {
    transform:  translate(-40px,2px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0vw);
  }
  81% {
    transform:  translate(-63px,-2px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0vw);
  }
  86% {
    transform:  translate(-72px,-19px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0vw);
  }
  90% {
    transform:  translate(-65px,-34px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0vw);
  }
  94% {
    transform:  translate(-47px,-39px)  rotate(-20deg) scale(0.9) perspective(450px) translateZ(0vw);
  }
  100% {background-position: 10000px;
    transform:  translate(-41px,0px) rotate(-20deg) scale(0.9) perspective(450px) translateZ(0vw);
  }
}



.carita_amarilla_little{
  height: 367px;
  width: calc(10000px / 25);          
  background: url('./assets/img/generales/amarillo.png'); 
  animation: carita_amarilla_little 9s steps(25) infinite;
  top: 41%;
  right: 0%;
  transform: rotate(-30deg);
}

@keyframes carita_amarilla_little{
  0% {background-position: 0;
    transform:  translate(0px, 0px)  rotate(-20deg) scale(0.2) ;
  }
  10% {
    transform:  translate(30px, 0px) rotate(-10deg)  scale(0.2) ;
  }
  20% {
    transform:  translate(27px, -27px)  rotate(-10deg) scale(0.2) ;
  }
  30% {
    transform:  translate(10px,-25px)  rotate(-20deg) scale(0.2) ;
  }
  40% {
    transform:  translate(8px, 0px)  rotate(-10deg) scale(0.2) ;
  }
  50% {
    transform:  translate(25px, -3px)  rotate(-10deg) scale(0.2) ;
  }
  60% {
    transform:  translate(36px,-14px)  rotate(-20deg) scale(0.2) ;
  }
  70% {
    transform:  translate(47px,-44px)  rotate(-20deg) scale(0.2) ;
  }
  80% {
    transform:  translate(30px,-41px)  rotate(-10deg) scale(0.2) ;
  }
  90% {
    transform:  translate(29px,-22px) rotate(-10deg)  scale(0.2);
  }
  100% {background-position: 10000px;
    transform:  translate( 0px, 0px)  rotate(-20deg) scale(0.2);
  }
}

.carita_verde{
  height: 476px;
  width: calc(12500px / 25);          
  background: url('./assets/img/generales/verde.png'); 
  animation: carita_verde 10s steps(25) infinite;

  right: 10%;
  top: 55%;

}


@keyframes carita_verde{
  0% {background-position: 0;
    transform:  translate(-14px,0px)  rotate(20deg) scale(0.5);
  }
  3% {
    transform:  translate(-24px,0px)  rotate(19deg) scale(0.5);
  }
  6% {
    transform:  translate(-31px,-10px)  rotate(17deg) scale(0.5);
  }
  9% {
    transform:  translate(-33px,-19px)  rotate(17deg) scale(0.5);
  }
  12% {
    transform:  translate(-34px,-27px)  rotate(17deg) scale(0.5);
  }
  16% {
    transform:  translate(-29px,-33px)  rotate(16deg) scale(0.5);
  }
  19% {
    transform:  translate(-24px,-37px)  rotate(15deg) scale(0.5);
  }
  23% {
    transform:  translate(-17px,-37px)  rotate(15deg) scale(0.5);
  }
  27% {
    transform:  translate(-10px,-33px)  rotate(15deg) scale(0.5);
  }
  31% {
    transform:  translate(-5px,-23px)  rotate(16deg) scale(0.5);
  }
  35% {
    transform:  translate(-2px,-15px)  rotate(17deg) scale(0.5);
  }
  41% {
    transform:  translate(-11px,-4px)  rotate(17deg) scale(0.5);
  }
  44% {
    transform:  translate(-24px,3px)  rotate(17deg) scale(0.5);
  }
  49% {
    transform:  translate(-40px,-6px)  rotate(19deg) scale(0.5);
  }
  53% {
    transform:  translate(-41px,-21px)  rotate(20deg) scale(0.5);
  }
  58% {
    transform:  translate(-35px,-36px)  rotate(20deg) scale(0.5);
  }
  61% {
    transform:  translate(-23px,-44px)  rotate(20deg) scale(0.5);
  }
  64% {
    transform:  translate(-15px,-38px)  rotate(20deg) scale(0.5);
  }
  68% {
    transform:  translate(-8px,-26px)  rotate(20deg) scale(0.5);
  }
  73% {
    transform:  translate(-5px,-10px)  rotate(20deg) scale(0.5);
  }
  77% {
    transform:  translate(-13px,2px)  rotate(20deg) scale(0.5);
  }
  81% {
    transform:  translate(-36px,-2px)  rotate(20deg) scale(0.5);
  }
  86% {
    transform:  translate(-45px,-19px)  rotate(20deg) scale(0.5);
  }
  90% {
    transform:  translate(-38px,-34px)  rotate(20deg) scale(0.5);
  }
  94% {
    transform:  translate(-20px,-39px)  rotate(20deg) scale(0.5);
  }
  100% {background-position: 12500px;
    transform:  translate(-14px,0px) rotate(20deg) scale(0.5);
  }
}




.carita_verde_big{
  height: 476px;
  width: calc(12500px / 25);          
  background: url('./assets/img/generales/verde.png'); 
  animation: carita_verde_big 9s steps(25) infinite;

  right: 33%;
  top: -46%;

}

@keyframes carita_verde_big{
  0% {background-position: 0;
    transform:  translate(-2px, 8px) rotate(-30deg)   scale(0.8);
  }
  6% {
    transform:  translate(-23px,-14px) rotate(-30deg)   scale(0.8);
  }
  15% {
    transform:  translate(-37px,4px) rotate(-30deg)   scale(0.8);
  }
  21% {
    transform:  translate(-29px,22px) rotate(-30deg)   scale(0.8);
  }
  25% {
    transform:  translate(-9px,9px) rotate(-30deg)   scale(0.8);
  }
  36% {
    transform:  translate(-3px,-23px) rotate(-30deg)   scale(0.8);
  }
  44% {
    transform:  translate(-29px,-30px) rotate(-30deg)   scale(0.8);
  }
  52% {
    transform:  translate(-9px,-12px) rotate(-30deg)   scale(0.8);
  }
  63% {
    transform:  translate(-28px,4px) rotate(-30deg)   scale(0.8);
  }
  70% {
    transform:  translate(-41px,-29px) rotate(-30deg)   scale(0.8);
  }
  78% {
    transform:  translate(-2px,-31px) rotate(-30deg)   scale(0.8);
  }
  88% {
    transform:  translate(-30px, -20px) rotate(-30deg)   scale(0.8);
  }
  100% {background-position: 12500px; 
    transform:  translate(-2px,8px) rotate(-30deg)   scale(0.8);
  }
}


.carita_azul{
  height: 126px;
  width: calc(3750px / 25);          
  background: url('./assets/img/generales/azul.png'); 
  animation: carita_azul 8s steps(25) infinite;

  top: 79%;
  right: 64%;
  transform: rotate(-30deg);

}


@keyframes carita_azul{
  0% {background-position: 0;
    transform:  translate(28px, 8px)  rotate(-30deg) scale(0.8);
  }
  10% {
    transform:  translate(63px, 12px) rotate(-30deg) scale(0.8);
  }
  20% {
    transform:  translate(46px, 28px) rotate(-30deg) scale(0.8);
  }
  30% {
    transform:  translate(7px,- 7px) rotate(-30deg) scale(0.8);
  }
  40% {
    transform:  translate(7px,- 2px) rotate(-30deg) scale(0.8);
  }

  50% {
    transform:  translate(64px, 30px) rotate(-30deg) scale(0.8);
  }

  60% {
    transform:  translate(22px, 29px) rotate(-30deg) scale(0.8);
  }
  70% {
    transform:  translate(34px, 19px) rotate(-30deg) scale(0.8);
  }
  80% {
    transform:  translate(44px, 24px) rotate(-30deg) scale(0.9);
  }
  90% {
    transform:  translate(21px, 12px) rotate(-30deg) scale(0.8);
  }
  100% {background-position: 3750px;
    transform:  translate(28px, 8px)  rotate(-30deg) scale(0.8);
  }
}

.carita_morado{
  height: 551px;
  width: calc(30000px / 50);          
  background: url('./assets/img/generales/morado.png'); 
  animation: carita_morado 8s steps(50) infinite;

  right: -11%;
  top: -19%;
}

@keyframes carita_morado{
  0% {background-position: 0;
    transform:  translate(-21px,0px)  rotate(45deg) scale(0.9);
  }
  3% {
    transform:  translate(-31px,0px)  rotate(40deg) scale(0.9);
  }
  6% {
    transform:  translate(-38px,-10px)  rotate(40deg) scale(0.9);
  }
  9% {
    transform:  translate(-40px,-19px)  rotate(40deg) scale(0.9);
  }
  12% {
    transform:  translate(-41px,-27px)  rotate(40deg) scale(0.9);
  }
  16% {
    transform:  translate(-36px,-33px)  rotate(40deg) scale(0.9);
  }
  19% {
    transform:  translate(-31px,-37px)  rotate(40deg) scale(0.9);
  }
  23% {
    transform:  translate(-24px,-37px)  rotate(40deg) scale(0.9);
  }
  27% {
    transform:  translate(-17px,-33px)  rotate(40deg) scale(0.9);
  }
  31% {
    transform:  translate(-12px,-23px)  rotate(40deg) scale(0.9);
  }
  35% {
    transform:  translate(-9px,-15px)  rotate(40deg) scale(0.9);
  }
  41% {
    transform:  translate(-18px,-4px)  rotate(45deg) scale(0.9);
  }
  44% {
    transform:  translate(-31px,3px)  rotate(45deg) scale(0.9);
  }
  49% {
    transform:  translate(-47px,-6px)  rotate(45deg) scale(0.9);
  }
  53% {
    transform:  translate(-48px,-21px)  rotate(45deg) scale(0.9);
  }
  58% {
    transform:  translate(-42px,-36px)  rotate(45deg) scale(0.9);
  }
  61% {
    transform:  translate(-30px,-44px)  rotate(45deg) scale(0.9);
  }
  64% {
    transform:  translate(-22px,-38px)  rotate(45deg) scale(0.9);
  }
  68% {
    transform:  translate(-15px,-26px)  rotate(45deg) scale(0.9);
  }
  73% {
    transform:  translate(-12px,-10px)  rotate(45deg) scale(0.9);
  }
  77% {
    transform:  translate(-20px,2px)  rotate(45deg) scale(0.9);
  }
  81% {
    transform:  translate(-43px,-2px)  rotate(45deg) scale(0.9);
  }
  86% {
    transform:  translate(-52px,-19px)  rotate(45deg) scale(0.9);
  }
  90% {
    transform:  translate(-45px,-34px)  rotate(45deg) scale(0.9);
  }
  94% {
    transform:  translate(-27px,-39px)  rotate(45deg) scale(0.9);
  }
  100% {background-position: 30000px;
    transform:  translate(-21px,0px) rotate(45deg) scale(0.9);
  }
}


.carita_morado_little{
  height: 551px;
  width: calc(30000px / 50);          
  background: url('./assets/img/generales/morado.png'); 
  animation: carita_morado_little 8s steps(50) infinite;

  right: 75%;
  top: 50%;
  /* transform: scale(0.8) rotate(40deg) !important; */
}



@keyframes carita_morado_little{
  0% {background-position: 0;
    transform:  translate(0px,0px)  rotate(-20deg) scale(0.4) ;
  }
  10% {
    transform:  translate(-31px,-24px) rotate(-15deg) scale(0.4) ;
  }
  20% {
    transform:  translate(-7px,-47px)  rotate(-15deg) scale(0.4) ;
  }
  30% {
    transform:  translate(13px,-30px)  rotate(-20deg) scale(0.4) ;
  }
  40% {
    transform:  translate(-6px,-6px)  rotate(-20deg) scale(0.4) ;
  }
  50% {
    transform:  translate(-35px,-18px)  rotate(-15deg) scale(0.4) ;
  }
  60% {
    transform:  translate(-36px,-14px)  rotate(-15deg) scale(0.4) ;
  }
  70% {
    transform:  translate(-47px,-44px)  rotate(-20deg) scale(0.4) ;
  }
  80% {
    transform:  translate(-30px,-41px)  rotate(-20deg) scale(0.4) ;
  }
  90% {
    transform:  translate(-29px,-22px) rotate(-20deg) scale(0.4);
  }
  100% {background-position: 30000px;
    transform:  translate( 0px, 0px)  rotate(-20deg) scale(0.4);
  }
}


.carita_roja{
  height: 276px;
  width: calc(15000px / 50);          
  background: url('./assets/img/generales/rojo.png'); 
  animation: carita_roja 8s steps(50) infinite;

  /* transform: rotate(30deg) scale(0.8); */
  top: 35%;
  right: 70%;
}


@keyframes carita_roja{
  0% {background-position: 0px;
    transform:  translate(-14px,0px)  rotate(20deg) scale(0.5);
  }
  3% {
    transform:  translate(-24px,0px)  rotate(19deg) scale(0.5);
  }
  6% {
    transform:  translate(-31px,-10px)  rotate(17deg) scale(0.5);
  }
  9% {
    transform:  translate(-33px,-19px)  rotate(17deg) scale(0.51);
  }
  12% {
    transform:  translate(-34px,-27px)  rotate(17deg) scale(0.51);
  }
  16% {
    transform:  translate(-29px,-33px)  rotate(16deg) scale(0.51);
  }
  19% {
    transform:  translate(-24px,-37px)  rotate(15deg) scale(0.51);
  }
  23% {
    transform:  translate(-17px,-37px)  rotate(15deg) scale(0.51);
  }
  27% {
    transform:  translate(-10px,-33px)  rotate(15deg) scale(0.51);
  }
  31% {
    transform:  translate(-5px,-23px)  rotate(16deg) scale(0.51);
  }
  35% {
    transform:  translate(-2px,-15px)  rotate(17deg) scale(0.51);
  }
  41% {
    transform:  translate(-11px,-4px)  rotate(17deg) scale(0.51);
  }
  44% {
    transform:  translate(-24px,3px)  rotate(17deg) scale(0.51);
  }
  49% {
    transform:  translate(-40px,-6px)  rotate(19deg) scale(0.51);
  }
  53% {
    transform:  translate(-41px,-21px)  rotate(20deg) scale(0.5);
  }
  58% {
    transform:  translate(-35px,-36px)  rotate(20deg) scale(0.5);
  }
  61% {
    transform:  translate(-23px,-44px)  rotate(20deg) scale(0.5);
  }
  64% {
    transform:  translate(-15px,-38px)  rotate(20deg) scale(0.5);
  }
  68% {
    transform:  translate(-8px,-26px)  rotate(20deg) scale(0.5);
  }
  73% {
    transform:  translate(-5px,-10px)  rotate(20deg) scale(0.5);
  }
  77% {
    transform:  translate(-13px,2px)  rotate(20deg) scale(0.5);
  }
  81% {
    transform:  translate(-36px,-2px)  rotate(20deg) scale(0.5);
  }
  86% {
    transform:  translate(-45px,-19px)  rotate(20deg) scale(0.5);
  }
  90% {
    transform:  translate(-38px,-34px)  rotate(20deg) scale(0.5);
  }
  94% {
    transform:  translate(-20px,-39px)  rotate(20deg) scale(0.5);
  }
  100% {background-position: 15000px;
    transform:  translate(-14px,0px) rotate(20deg) scale(0.5);
  }
}


/* ICONOS DEL MENÚ ARRIBA */
.programacion{
  background: url('./assets/img/generales/lineup.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 4%;
  height: 8%;
  right: 12%;
  top: 1%;
  z-index: 5000;
  cursor: pointer;
}

.lineUp{
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  margin: 0px auto;
  transform: translate(0px, 4%);
  text-align: center;
  transition: 1s;
  visibility: hidden;
  opacity: 0;
  width: 35%;
}

.lineUp img{
  width: 35%;
}

.lineUpMovil{
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  margin: 0px auto;
  transform: translate(0px, 10%);
  text-align: center;
  transition: 1s;
  visibility: hidden;
  opacity: 0;
}

.lineUpMovil img{
  width: 72%;
}

.cerrar{
  font-size: 25px;
  position: absolute;
  z-index: 2000;
  right: 5%;
  cursor: pointer;
  color: black;
  text-align: right;
  width: 25%;
  padding: 15px;
  font-weight: 600;
}

.fadeInLine{
  opacity: 1;
  visibility: visible;
}

.tienda_fep{
  background: url('./assets/img/generales/tiendafep.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 12%;
  height: 6%;
  right: 16%;
  top: 0%;
  z-index: 5000;
  cursor: pointer;
}

/* ICONOS DE FOOTER */
.logos_footer {
  position: absolute;
  width: 100%;
}

.logos_footer img{
  width: 100%;
  position: fixed;
  bottom: 0;
 }

.logos_footer .lateral{
  position: fixed;
  bottom: 1%;
  width: 0.7%;
  left: 1%;
}

.logos_footer .centro{
  position: fixed;
  bottom: 0.5%;
  width: 40%;
  right: 0;
  left: 0;
  margin: 0px auto;
}




 .movil{
  display: none;
}

.desktop{
display: block;
}

/* AQUI SERÁ RESPONSIVE PARA ANIMACIONES DE CARITAS */
@media only screen and (max-width: 1300px){
  .carita_morado {
    right: -20%;
    top: -30%;
}

  @keyframes carita_morado{
    0% {background-position: 0;
      transform:  translate(-21px,0px)  rotate(45deg) scale(0.6);
    }
    3% {
      transform:  translate(-31px,0px)  rotate(40deg) scale(0.6);
    }
    6% {
      transform:  translate(-38px,-10px)  rotate(40deg) scale(0.6);
    }
    9% {
      transform:  translate(-40px,-19px)  rotate(40deg) scale(0.6);
    }
    12% {
      transform:  translate(-41px,-27px)  rotate(40deg) scale(0.6);
    }
    16% {
      transform:  translate(-36px,-33px)  rotate(40deg) scale(0.6);
    }
    19% {
      transform:  translate(-31px,-37px)  rotate(40deg) scale(0.6);
    }
    23% {
      transform:  translate(-24px,-37px)  rotate(40deg) scale(0.6);
    }
    27% {
      transform:  translate(-17px,-33px)  rotate(40deg) scale(0.6);
    }
    31% {
      transform:  translate(-12px,-23px)  rotate(40deg) scale(0.6);
    }
    35% {
      transform:  translate(-9px,-15px)  rotate(40deg) scale(0.6);
    }
    41% {
      transform:  translate(-18px,-4px)  rotate(45deg) scale(0.6);
    }
    44% {
      transform:  translate(-31px,3px)  rotate(45deg) scale(0.6);
    }
    49% {
      transform:  translate(-47px,-6px)  rotate(45deg) scale(0.6);
    }
    53% {
      transform:  translate(-48px,-21px)  rotate(45deg) scale(0.6);
    }
    58% {
      transform:  translate(-42px,-36px)  rotate(45deg) scale(0.6);
    }
    61% {
      transform:  translate(-30px,-44px)  rotate(45deg) scale(0.6);
    }
    64% {
      transform:  translate(-22px,-38px)  rotate(45deg) scale(0.6);
    }
    68% {
      transform:  translate(-15px,-26px)  rotate(45deg) scale(0.6);
    }
    73% {
      transform:  translate(-12px,-10px)  rotate(45deg) scale(0.6);
    }
    77% {
      transform:  translate(-20px,2px)  rotate(45deg) scale(0.6);
    }
    81% {
      transform:  translate(-43px,-2px)  rotate(45deg) scale(0.6);
    }
    86% {
      transform:  translate(-52px,-19px)  rotate(45deg) scale(0.6);
    }
    90% {
      transform:  translate(-45px,-34px)  rotate(45deg) scale(0.6);
    }
    94% {
      transform:  translate(-27px,-39px)  rotate(45deg) scale(0.6);
    }
    100% {background-position: 30000px;
      transform:  translate(-21px,0px) rotate(45deg) scale(0.6);
    }
  }

  .carita_verde_big {
    top: -60%;
}
}


@media only screen and (max-width: 768px){
  .movil{
    display: block;
  }

  .desktop{
    display: none;
  }


}

@media only screen and (max-width: 500px){
  .tienda_fep {
    width: 25%;
    right: 23%;
    top: -45px;
}

  .programacion{
    width: 13%;
    right: 5%;
  }

  .cerrar {
    left: 60%;
}

  .carita_amarilla_little {
    top: 55%;
    right: -35%;
  }

  .carita_morado {
    right: -88%;
    top: -26%;
}

  @keyframes carita_morado{
    0% {background-position: 0;
      transform:  translate(-21px,0px)  rotate(45deg) scale(0.2);
    }
    3% {
      transform:  translate(-31px,0px)  rotate(40deg) scale(0.2);
    }
    6% {
      transform:  translate(-38px,-10px)  rotate(40deg) scale(0.2);
    }
    9% {
      transform:  translate(-40px,-19px)  rotate(40deg) scale(0.2);
    }
    12% {
      transform:  translate(-41px,-27px)  rotate(40deg) scale(0.2);
    }
    16% {
      transform:  translate(-36px,-33px)  rotate(40deg) scale(0.2);
    }
    19% {
      transform:  translate(-31px,-37px)  rotate(40deg) scale(0.2);
    }
    23% {
      transform:  translate(-24px,-37px)  rotate(40deg) scale(0.2);
    }
    27% {
      transform:  translate(-17px,-33px)  rotate(40deg) scale(0.2);
    }
    31% {
      transform:  translate(-12px,-23px)  rotate(40deg) scale(0.2);
    }
    35% {
      transform:  translate(-9px,-15px)  rotate(40deg) scale(0.2);
    }
    41% {
      transform:  translate(-18px,-4px)  rotate(45deg) scale(0.2);
    }
    44% {
      transform:  translate(-31px,3px)  rotate(45deg) scale(0.2);
    }
    49% {
      transform:  translate(-47px,-6px)  rotate(45deg) scale(0.2);
    }
    53% {
      transform:  translate(-48px,-21px)  rotate(45deg) scale(0.2);
    }
    58% {
      transform:  translate(-42px,-36px)  rotate(45deg) scale(0.2);
    }
    61% {
      transform:  translate(-30px,-44px)  rotate(45deg) scale(0.2);
    }
    64% {
      transform:  translate(-22px,-38px)  rotate(45deg) scale(0.2);
    }
    68% {
      transform:  translate(-15px,-26px)  rotate(45deg) scale(0.2);
    }
    73% {
      transform:  translate(-12px,-10px)  rotate(45deg) scale(0.2);
    }
    77% {
      transform:  translate(-20px,2px)  rotate(45deg) scale(0.2);
    }
    81% {
      transform:  translate(-43px,-2px)  rotate(45deg) scale(0.2);
    }
    86% {
      transform:  translate(-52px,-19px)  rotate(45deg) scale(0.2);
    }
    90% {
      transform:  translate(-45px,-34px)  rotate(45deg) scale(0.2);
    }
    94% {
      transform:  translate(-27px,-39px)  rotate(45deg) scale(0.2);
    }
    100% {background-position: 30000px;
      transform:  translate(-21px,0px) rotate(45deg) scale(0.2);
    }
  }
  .carita_amarilla {
    top: -17%;
    right: 17%;
}

  @keyframes carita_amarilla{
    0% {background-position: 0;
      transform:  translate(-41px,0px)  rotate(-20deg) scale(0.2)  perspective(550px) translateZ(0vw);
    }
    3% {
      transform:  translate(-51px,0px)  rotate(-15deg) scale(0.2)  perspective(550px) translateZ(0vw);
    }
    6% {
      transform:  translate(-58px,-10px)  rotate(-15deg) scale(0.2)  perspective(550px) translateZ(0vw);
    }
    9% {
      transform:  translate(-60px,-19px)  rotate(-15deg) scale(0.2)  perspective(550px) translateZ(0vw);
    }
    12% {
      transform:  translate(-61px,-27px)  rotate(-15deg) scale(0.2)  perspective(550px) translateZ(0vw);
    }
    16% {
      transform:  translate(-56px,-33px)  rotate(-15deg) scale(0.2)  perspective(550px) translateZ(0vw);
    }
    19% {
      transform:  translate(-51px,-37px)  rotate(-15deg) scale(0.2)  perspective(550px) translateZ(0vw);
    }
    23% {
      transform:  translate(-44px,-37px)  rotate(-15deg) scale(0.2) perspective(450px) translateZ(0.1vw);
    }
    27% {
      transform:  translate(-37px,-33px)  rotate(-15deg) scale(0.2) perspective(450px) translateZ(0.1vw);
    }
    31% {
      transform:  translate(-32px,-23px)  rotate(-15deg) scale(0.2) perspective(450px) translateZ(0.1vw);
    }
    35% {
      transform:  translate(-29px,-15px)  rotate(-15deg) scale(0.2) perspective(450px) translateZ(0.1vw);
    }
    41% {
      transform:  translate(-38px,-4px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0.1vw);
    }
    44% {
      transform:  translate(-51px,3px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0.1vw);
    }
    49% {
      transform:  translate(-67px,-6px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0.1vw);
    }
    53% {
      transform:  translate(-68px,-21px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0.1vw);
    }
    58% {
      transform:  translate(-62px,-36px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0.1vw);
    }
    61% {
      transform:  translate(-50px,-44px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0.2vw);
    }
    64% {
      transform:  translate(-42px,-38px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0.2vw);
    }
    68% {
      transform:  translate(-35px,-26px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0.2vw);
    }
    73% {
      transform:  translate(-32px,-10px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0.2vw);
    }
    77% {
      transform:  translate(-40px,2px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0vw);
    }
    81% {
      transform:  translate(-63px,-2px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0vw);
    }
    86% {
      transform:  translate(-72px,-19px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0vw);
    }
    90% {
      transform:  translate(-65px,-34px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0vw);
    }
    94% {
      transform:  translate(-47px,-39px)  rotate(-20deg) scale(0.2) perspective(450px) translateZ(0vw);
    }
    100% {background-position: 10000px;
      transform:  translate(-41px,0px) rotate(-20deg) scale(0.2) perspective(450px) translateZ(0vw);
    }
  }

  .carita_verde{  
    right: 15%;
    top: 60%;
  
  }
  
  
  @keyframes carita_verde{
    0% {background-position: 0;
      transform:  translate(-14px,0px)  rotate(20deg) scale(0.2);
    }
    3% {
      transform:  translate(-24px,0px)  rotate(19deg) scale(0.2);
    }
    6% {
      transform:  translate(-31px,-10px)  rotate(17deg) scale(0.2);
    }
    9% {
      transform:  translate(-33px,-19px)  rotate(17deg) scale(0.2);
    }
    12% {
      transform:  translate(-34px,-27px)  rotate(17deg) scale(0.2);
    }
    16% {
      transform:  translate(-29px,-33px)  rotate(16deg) scale(0.2);
    }
    19% {
      transform:  translate(-24px,-37px)  rotate(15deg) scale(0.2);
    }
    23% {
      transform:  translate(-17px,-37px)  rotate(15deg) scale(0.2);
    }
    27% {
      transform:  translate(-10px,-33px)  rotate(15deg) scale(0.2);
    }
    31% {
      transform:  translate(-5px,-23px)  rotate(16deg) scale(0.2);
    }
    35% {
      transform:  translate(-2px,-15px)  rotate(17deg) scale(0.2);
    }
    41% {
      transform:  translate(-11px,-4px)  rotate(17deg) scale(0.2);
    }
    44% {
      transform:  translate(-24px,3px)  rotate(17deg) scale(0.2);
    }
    49% {
      transform:  translate(-40px,-6px)  rotate(19deg) scale(0.2);
    }
    53% {
      transform:  translate(-41px,-21px)  rotate(20deg) scale(0.2);
    }
    58% {
      transform:  translate(-35px,-36px)  rotate(20deg) scale(0.2);
    }
    61% {
      transform:  translate(-23px,-44px)  rotate(20deg) scale(0.2);
    }
    64% {
      transform:  translate(-15px,-38px)  rotate(20deg) scale(0.2);
    }
    68% {
      transform:  translate(-8px,-26px)  rotate(20deg) scale(0.2);
    }
    73% {
      transform:  translate(-5px,-10px)  rotate(20deg) scale(0.2);
    }
    77% {
      transform:  translate(-13px,2px)  rotate(20deg) scale(0.2);
    }
    81% {
      transform:  translate(-36px,-2px)  rotate(20deg) scale(0.2);
    }
    86% {
      transform:  translate(-45px,-19px)  rotate(20deg) scale(0.2);
    }
    90% {
      transform:  translate(-38px,-34px)  rotate(20deg) scale(0.2);
    }
    94% {
      transform:  translate(-20px,-39px)  rotate(20deg) scale(0.2);
    }
    100% {background-position: 12500px;
      transform:  translate(-14px,0px) rotate(20deg) scale(0.2);
    }
  }

  .logos_footer .centro {
    width: 60%;
}

.logos_footer .lateral {
  width: 2%;
}
}

@media only screen and (max-height: 450px){
  .logos_footer{
    visibility: hidden;
  }

  .terminos_contenedor {
    width: 64%;

}

}